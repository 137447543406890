.Switch_root {
  position: relative;
  width: 5rem;
  height: 3rem;
}
.Switch_root .Switch_track {
  position: absolute;
  top: 0.25rem;
  width: 100%;
  height: calc(100% - 0.5rem);
  border-radius: 4rem;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: var(--color_divider);
}
.Switch_root .Switch_switch {
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 0;
  width: 3rem;
  height: 3rem;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: var(--color_divider);
  box-shadow: 0 0 3px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.Switch_root.Switch_disabled {
  opacity: 0.5;
}
.Switch_root.Switch_checked .Switch_switch {
  background-color: var(--color_primary_main);
}
.Switch_root.Switch_checked.Switch_size_sm .Switch_switch {
  left: calc(100% - 2rem);
}
.Switch_root.Switch_checked.Switch_size_md .Switch_switch {
  left: calc(100% - 3rem);
}
.Switch_root.Switch_checked .Switch_track {
  background-color: var(--color_primary_main);
}
.Switch_root.Switch_color_primary {
  color: var(--color_primary_main);
}
.Switch_root.Switch_color_secondary {
  color: var(--color_secondary_main);
}
.Switch_root.Switch_size_sm {
  width: 4rem;
  height: 2rem;
}
.Switch_root.Switch_size_sm .Switch_switch {
  width: 2rem;
  height: 2rem;
}
.Switch_root.Switch_size_md {
  width: 5rem;
  height: 3rem;
}
.Switch_root.Switch_size_md .Switch_switch {
  width: 3rem;
  height: 3rem;
}