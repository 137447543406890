.EPXTool_root {
  padding: 1rem;
}

.EPXTool_response {
  background-color: rgb(255, 255, 255);
  padding: 1rem 2rem;
}

.EPXTool_json-view-wrapper {
  background-color: white;
}