.CheckTransactions_root {
  padding: 1rem 0.5rem;
}

.CheckTransactions_title {
  padding: 0 1rem;
}

.CheckTransactions_charge {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background-color: transparent;
  border-radius: 5rem;
  border: 1px solid var(--color_divider);
}