.SGColors_color-container {
  height: 5rem;
  width: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SGColors_color-label {
  padding: 0.5rem;
  background-color: var(--color_bg_main);
}