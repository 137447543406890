.FormField_touch-input {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.FormField_number_input input::-webkit-outer-spin-button, .FormField_number_input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.FormField_number_input input[type=number] {
  -moz-appearance: textfield;
}