.DevToolsView_root {
  position: fixed;
  left: 0;
  bottom: 0;
}
.DevToolsView_root.DevToolsView_root-expanded {
  width: 100%;
  right: 0;
  z-index: 99;
}
.DevToolsView_root.DevToolsView_root-expanded .DevToolsView_content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2rem;
  height: 80vh;
  width: 100%;
  opacity: 1;
  background-color: rgba(30, 30, 30, 0.9);
  color: rgb(255, 255, 255);
}
.DevToolsView_root.DevToolsView_root-expanded .DevToolsView_content .DevToolsView_tools {
  display: block;
}

.DevToolsView_content {
  background-color: rgb(255, 255, 255);
  color: black;
  opacity: 0.4;
  padding: 1rem;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.DevToolsView_tools {
  display: none;
  overflow-y: auto;
}

.DevToolsView_tools-content {
  padding: 1rem;
}