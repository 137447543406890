.EditPaymentModal_form {
  grid-area: frm;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.EditPaymentModal_button-wrapper {
  grid-area: btn;
  height: 100%;
}

.EditPaymentModal_button {
  position: fixed;
  top: 28%;
  text-transform: none;
}

.EditPaymentModal_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: ". frm btn";
  align-items: center;
  height: 100%;
}