.FormFieldUnit_root {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
}

.FormFieldUnit_input {
  max-width: 9rem;
  text-align: center;
  /* Body/Regular */
  font-style: normal;
  /* Body/Regular */
  font-size: 2rem; /* 20px */
  line-height: 140%; /* 28px */
  /* Body/Regular */
  font-weight: 400;
}

.FormFieldUnit_unit {
  justify-self: center;
  /* Body/Regular */
  font-style: normal;
  /* Body/Regular */
  font-size: 2rem; /* 20px */
  line-height: 140%; /* 28px */
  /* Body/Regular */
  font-weight: 400;
}