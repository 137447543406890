.CheckMergeModal_content_wrapper {
  height: 100%;
  display: grid;
  grid-template-rows: auto auto 1fr;
  gap: 2rem;
}

.CheckMergeModal_transferBar {
  background-color: var(--color_grey_900);
  margin-inline: 4rem;
}

.CheckMergeModal_filterBar {
  display: flex;
  gap: 1rem;
  margin-inline: 4rem;
}

.CheckMergeModal_tableRow {
  background-color: transparent;
}

.CheckMergeModal_tableRowSelected {
  background-color: var(--color_grey_900);
}

.CheckMergeModal_tableHeader {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.CheckMergeModal_radio {
  padding: 0;
}