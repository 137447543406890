.AuthRequestNotification_root {
  padding: 3rem;
}

.AuthRequestNotification_message {
  wordBreak: break-word;
  flexGrow: 1;
}

.AuthRequestNotification_time {
  width: 6rem;
  color: var(--color_text_secondary);
}

.AuthRequestNotification_icon {
  font-size: 2rem;
  width: 3rem;
  lineHeight: 3rem;
  text-align: center;
  borderRadius: 50%;
}