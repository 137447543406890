.TerminalDetails_root {
  display: inline-flex;
}

.TerminalDetails_btnLabel {
  margin-left: 1rem;
  color: var(--color_grey_500);
}

.TerminalDetails_clock {
  margin-bottom: 3.2rem;
}

.TerminalDetails_input_spacer {
  height: 5rem;
}

.TerminalDetails_input_validation_spacer {
  height: 3rem;
}