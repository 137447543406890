.Alert_root {
  background-color: var(--color_primary_main);
  border-radius: 0.8rem;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.Alert_root.Alert_severity_success {
  background-color: var(--color_success_main);
}
.Alert_root.Alert_severity_success .Alert_action {
  color: var(--color_success_contrast_text);
}
.Alert_root.Alert_severity_success .Alert_close_icon_cta {
  border: 0;
}
.Alert_root.Alert_severity_info {
  background-color: var(--color_info_main);
}
.Alert_root.Alert_severity_info .Alert_action {
  color: var(--color_info_contrast_text);
}
.Alert_root.Alert_severity_info .Alert_close_icon_cta {
  border: 0;
}
.Alert_root.Alert_severity_warning {
  background-color: var(--color_warn_main);
  color: var(--color_warn_contrast_text);
}
.Alert_root.Alert_severity_warning .Alert_action {
  color: var(--color_warn_contrast_text);
}
.Alert_root.Alert_severity_warning .Alert_severity_icon {
  color: var(--color_warn_contrast_text);
}
.Alert_root.Alert_severity_warning .Alert_close_icon_cta {
  color: var(--color_warn_contrast_text);
  border: 0;
}
.Alert_root.Alert_severity_error {
  background-color: var(--color_error_main);
}
.Alert_root.Alert_severity_error .Alert_action {
  color: var(--color_error_contrast_text);
}
.Alert_root.Alert_severity_error .Alert_close_icon_cta {
  border: 0;
}

.Alert_action {
  color: inherit;
}
.Alert_action button {
  border: 1px solid var(--color_warn_contrast_text);
}
.Alert_action svg {
  font-size: 1.5rem;
}

.Alert_custom_action {
  margin-bottom: -1rem;
  /* Body/Regular */
  font-style: normal;
  /* Body/Regular */
  font-size: 2rem; /* 20px */
  line-height: 140%; /* 28px */
  /* Body/SemiBold */
  font-weight: 700;
  align-self: flex-end;
}
.Alert_custom_action svg {
  font-size: 2rem;
}