.MenuItemQoHModal_field {
  border-bottom: 1px solid var(--color_divider);
  display: flex;
  justify-content: space-between;
  padding: 3rem 1rem 2rem 1rem;
  flex-wrap: wrap;
}
.MenuItemQoHModal_field > .Flex_root:first-child {
  padding-bottom: 3rem;
}

.MenuItemQoHModal_variants label {
  color: var(--color_text_primary);
}

.MenuItemQoHModal_number_input.FormFieldInput_root.FormFieldInput_size_md {
  /* H4/Bold */
  font-family: "Century-Gothic-Pro";
  font-size: 4.8rem; /* 48px */
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 48px */
}

.MenuItemQoHModal_quantity label {
  color: var(--color_text_primary);
}