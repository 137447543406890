.FormFieldNumberStepper_label {
  position: relative;
  transform: unset;
  margin-bottom: 1rem;
}

.FormFieldNumberStepper_stepper-value {
  pointer-events: none;
  min-width: 2rem;
  text-align: center;
  flex-grow: 1;
  field-sizing: content;
}

.FormFieldNumberStepper_pill-left {
  border-top-left-radius: 5rem;
  border-bottom-left-radius: 5rem;
}

.FormFieldNumberStepper_pill-right {
  border-top-right-radius: 5rem;
  border-bottom-right-radius: 5rem;
}