.FormFieldText_form-control-root {
  position: relative;
  display: inline-flex;
  width: 100%;
}
.FormFieldText_form-control-root .FormFieldText_content {
  display: inline-flex;
  flex-direction: row;
  gap: 0.25rem;
  align-items: flex-end;
  background-color: rgba(255, 255, 255, 0.09);
  padding: 1.5rem;
}
.FormFieldText_form-control-root .FormFieldText_content.FormFieldText_variant_underline {
  background-color: unset;
  border-bottom: 1px solid var(--color_divider);
}
.FormFieldText_form-control-root .FormFieldText_content.FormFieldText_variant_underline .FormFieldText_input {
  text-align: right;
}
.FormFieldText_form-control-root .FormFieldText_content.FormFieldText_variant_underline_multiline {
  background-color: unset;
  border-bottom: 1px solid var(--color_divider);
}
.FormFieldText_form-control-root.FormFieldText_size_sm .FormFieldText_content {
  padding: 0.75rem;
  min-height: 4rem;
}
.FormFieldText_form-control-root.FormFieldText_size_md .FormFieldText_content {
  padding: 1.5rem;
  min-height: 5rem;
}
.FormFieldText_form-control-root:focus-within .FormFieldText_label, .FormFieldText_form-control-root.FormFieldText_filled .FormFieldText_label {
  transform: translate(0rem, -0.25rem) scale(0.8);
}
.FormFieldText_form-control-root:focus-within .FormFieldText_label.FormFieldText_variant_underline, .FormFieldText_form-control-root.FormFieldText_filled .FormFieldText_label.FormFieldText_variant_underline {
  transform: none;
}
.FormFieldText_form-control-root.FormFieldText_required .FormFieldText_label::after {
  content: " *";
  display: inline;
}

.FormFieldText_message {
  left: 0;
}

.FormFieldText_input-and-label {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.FormFieldText_label {
  display: block;
  text-align: left;
  max-width: 100%;
  user-select: none;
  color: var(--color_text_secondary);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  transform-origin: top left;
  transform: translate(0, 0) scale(1);
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.FormFieldText_label.FormFieldText_variant_underline {
  color: var(--color_text_primary);
  transform-origin: unset;
  transform: none;
  transition: none;
  padding: 1rem 0;
}

.FormFieldText_adorn_start {
  padding: 1rem 0;
}

.FormFieldText_adorn_end {
  padding: 1rem 0;
}