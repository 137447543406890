.MenuItemButton_root {
  position: relative;
  display: inline-flex;
  align-items: center;
  outline: 0;
  margin: 0;
  padding: 0 2rem;
  user-select: none;
  vertical-align: middle;
  -webkit-appearance: none;
  text-decoration: none;
  text-transform: capitalize;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 0.5rem;
  border-left: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  gap: 0.25rem;
}

.MenuItemButton_label {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  max-width: 100%;
  overflow: hidden;
  text-align: left;
  /* Body/Regular */
  font-style: normal;
  /* Body/Regular */
  font-size: 2rem; /* 20px */
  line-height: 140%; /* 28px */
  /* Body/Regular */
  font-weight: 400;
  color: var(--color_text_primary);
}

.MenuItemButton_star {
  position: absolute;
  color: var(--color_menu_fave);
  top: 0.25rem;
  right: 0.25rem;
}

.MenuItemButton_qty {
  position: absolute;
  bottom: 0.25rem;
  right: 0.25rem;
  color: var(--color_grey_100);
  background-color: #111111;
}

.MenuItemButton_view-only-mode {
  pointer-events: none;
}

.MenuItemButton_disabled {
  opacity: 0.5;
}