.FormFieldDropdownList_text-field {
  border-bottom: 1px solid var(--color_divider);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3rem 1rem 1rem 1rem;
  align-items: center;
}

.FormFieldDropdownList_options {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.1rem;
}

.FormFieldDropdownList_option {
  border-radius: 0;
  background-color: var(--color_bg_main);
}
.FormFieldDropdownList_option .CTA_label {
  text-align: left;
  width: 100%;
}
.FormFieldDropdownList_option.CTA_selected {
  background-color: var(--color_bg_main);
}

.FormFieldDropdownList_root > *:not(:last-child) {
  margin-bottom: 0.5rem;
}

.FormFieldDropdownList_title {
  background-color: var(--color_grey_800);
  text-transform: uppercase;
  padding: 1.5rem;
}

.FormFieldDropdownList_select-all {
  padding: 1.5rem 0 1rem 2.5rem;
}

.FormFieldDropdownList_option-description {
  margin-top: 1rem;
}

.FormFieldDropdownList_full_height {
  height: 100%;
}
.FormFieldDropdownList_full_height .FormFieldDropdownList_list {
  max-height: 30rem;
  overflow: auto;
}
.FormFieldDropdownList_full_height .FormFieldDropdownList_list-with-buttons {
  max-height: 30rem;
  overflow: auto;
}