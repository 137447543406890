.ModifierBreadCrumbs_root {
  width: 100%;
  height: 6.8rem;
  overflow-x: auto;
}

.ModifierBreadCrumbs_scrollContent {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.4rem;
  width: max-content;
  touch-action: pan-x;
}
.ModifierBreadCrumbs_scrollContent > * {
  touch-action: pan-x;
}

.ModifierBreadCrumbs_divider {
  user-select: none;
  pointer-events: none;
}