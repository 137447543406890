.Layout_default {
  display: flex;
  gap: 2rem;
}

.Layout_grid {
  display: grid;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}

.Layout_inline-grid {
  display: inline-grid;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}

.Layout_fill {
  grid-column-start: 1;
  grid-column-end: -1;
}

.Layout_form {
  display: grid;
  grid-column-gap: 1rem;
  margin-bottom: 3rem;
}
.Layout_form > .FormFieldContainer_root:not(:last-child) {
  margin-bottom: 3rem;
}

.Layout_col1 {
  grid-template-columns: repeat(1, 1fr);
}

.Layout_col2 {
  grid-template-columns: repeat(2, 1fr);
}

.Layout_col3 {
  grid-template-columns: repeat(3, 1fr);
}

.Layout_col4 {
  grid-template-columns: repeat(4, 1fr);
}

.Layout_col5 {
  grid-template-columns: repeat(5, 1fr);
}

.Layout_col6 {
  grid-template-columns: repeat(6, 1fr);
}

.Layout_col7 {
  grid-template-columns: repeat(7, 1fr);
}

.Layout_col8 {
  grid-template-columns: repeat(8, 1fr);
}