.ChecksFloorPlan_dropdown {
  width: 20rem;
}

.ChecksFloorPlan_editorRoot {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: minmax(60vw, 1fr) auto;
  gap: 1rem;
}

.ChecksFloorPlan_editorHeader {
  grid-column: 1/3;
}

.ChecksFloorPlan_grid {
  height: 100%;
  display: grid;
  grid-template-columns: minmax(80vw, 1fr) auto;
}
.ChecksFloorPlan_grid.ChecksFloorPlan_viewer {
  grid-template-rows: auto 1fr;
}
.ChecksFloorPlan_grid.ChecksFloorPlan_viewer.ChecksFloorPlan_noEdit {
  grid-template-rows: minmax(0, 1fr);
}