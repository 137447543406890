.CheckFeesModal_content > div {
  margin-top: 5rem;
}

.CheckFeesModal_autoFee {
  margin: 0 -4rem;
  padding: 1.5rem 4rem 0;
  background-color: var(--color_bg_tertiary);
}

.CheckFeesModal_feeGrid {
  display: grid;
  grid-template-columns: 1fr auto auto auto;
  align-items: center;
  gap: 3rem;
}

.CheckFeesModal_divider {
  grid-column: 1/5;
}

.CheckFeesModal_appliedValue {
  min-width: 9rem;
}