.OrgSelect_searchFieldWrapper {
  min-width: 50%;
}

.OrgSelect_list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-top: 0;
  gap: 0.5rem;
  max-height: 25vh;
}