.ProvisionItemCta_root {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  position: relative;
  height: unset;
  align-items: flex-start;
  min-width: 40vw;
  min-height: 8.5rem;
}