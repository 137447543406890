.ToastAlertSkin_alertRoot {
  background-color: var(--color_grey_900);
  align-items: center;
  border-radius: 1rem;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.ToastAlertSkin_title {
  color: var(--color_text_primary);
}

.ToastAlertSkin_message {
  color: var(--color_text_primary);
  /* Body/Regular */
  font-style: normal;
  /* BodySM */
  font-size: 1.6rem; /* 16px */
  line-height: 140%; /* 22.4px */
  /* Body/Regular */
  font-weight: 400;
  display: inline-block;
}
.ToastAlertSkin_message ul {
  padding: 0;
  padding-left: 1rem;
}

.ToastAlertSkin_action {
  align-self: flex-start;
  color: var(--color_text_primary);
}
.ToastAlertSkin_action svg {
  font-size: 1.5rem;
}

.ToastAlertSkin_custom_action {
  margin-bottom: -1rem;
  /* Body/Regular */
  font-style: normal;
  /* Body/Regular */
  font-size: 2rem; /* 20px */
  line-height: 140%; /* 28px */
  /* Body/Regular */
  font-weight: 400;
  align-self: flex-end;
}
.ToastAlertSkin_custom_action svg {
  font-size: 2rem;
}