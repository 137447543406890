.Divider_root {
  margin: 0;
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color: var(--color_divider);
  border-bottom-width: thin;
}
.Divider_root.Divider_stubby {
  border-top: 0.3rem solid var(--color_divider);
  border-bottom: none;
  width: 5%;
  margin: 0;
}
.Divider_root.Divider_absolute {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.Divider_root.Divider_light {
  border-color: var(--color_divider_lite);
}
.Divider_root.Divider_dark {
  border-color: var(--color_divider_dark);
}
.Divider_root.Divider_inset {
  margin-left: 7rem;
}
.Divider_root.Divider_middle-horizontal {
  margin-left: 2rem;
  margin-right: 2rem;
}
.Divider_root.Divider_middle-vertical {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.Divider_root.Divider_orientation-vertical {
  height: 100%;
  border-bottom-width: 0;
  border-right-width: thin;
}
.Divider_root.Divider_flexItem {
  align-self: stretch;
  height: auto;
}
.Divider_root.Divider_has-children-horizontal {
  display: flex;
  white-space: nowrap;
  text-align: center;
  border: 0;
}
.Divider_root.Divider_has-children-horizontal::before, .Divider_root.Divider_has-children-horizontal::after {
  content: "";
  align-self: center;
  border-top: thin solid var(--color_divider);
}
.Divider_root.Divider_has-children-vertical {
  display: flex;
  white-space: nowrap;
  text-align: center;
  border: 0;
}
.Divider_root.Divider_has-children-vertical::before, .Divider_root.Divider_has-children-vertical::after {
  content: "";
  height: 100%;
  border-left: thin solid var(--color_divider);
}

.Divider_margin-xs {
  margin: 0.5rem;
}

.Divider_margin-sm {
  margin: 1rem;
}

.Divider_margin-md {
  margin: 2rem;
}

.Divider_margin-lg {
  margin: 4rem;
}