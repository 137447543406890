.FormFieldContainer_root {
  position: relative;
}

.FormFieldContainer_hidden {
  display: none;
}

.FormFieldContainer_message {
  position: relative;
  width: calc(100% - 1rem);
  left: 1rem;
}
.FormFieldContainer_message .FormFieldContainer_messageWrapper {
  width: 100%;
  position: absolute;
  margin: 0.3rem 0 0 0;
}