.CheckPanelFooter_root {
  position: relative;
  overflow: visible;
  flex-shrink: 0;
  background-color: transparent;
  transition: height 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.CheckPanelFooter_root.CheckPanelFooter_viewModeMain .CheckPanelFooter_checkDetails {
  top: 0;
}
.CheckPanelFooter_root.CheckPanelFooter_viewModeOverlay .CheckPanelFooter_overlayContainer {
  top: 0;
}

.CheckPanelFooter_checkDetails {
  transition: top 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: var(--color_grey_800);
  position: absolute;
  top: calc(100% + 1rem);
  width: 100%;
  z-index: 3;
  box-shadow: 0px -10px 8px 0px rgba(0, 0, 0, 0.25);
}

.CheckPanelFooter_handle {
  display: flex;
  justify-content: center;
  align-content: center;
}
.CheckPanelFooter_handle::after {
  margin: 2rem 0;
  content: "";
  width: 6rem;
  height: 0.7rem;
  background-color: var(--color_divider);
  border-radius: 5rem;
}

.CheckPanelFooter_total {
  display: grid;
  grid-template-columns: auto auto;
  grid-row-gap: 1rem;
  padding: 1rem 2rem 1rem 2rem;
}
.CheckPanelFooter_total > :nth-child(even) {
  justify-self: end;
}

.CheckPanelFooter_actions {
  display: grid;
  grid-template-columns: 6fr 1fr;
  grid-column-gap: 1rem;
  padding: 1rem 1rem 2rem 2rem;
}

.CheckPanelFooter_overlayContainer {
  transition: top 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  position: absolute;
  background-color: var(--color_grey_800);
  top: calc(100% + 3rem);
  z-index: 4;
  width: 100%;
}
.CheckPanelFooter_overlayContainer::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2rem;
  top: -2rem;
  left: 0;
  background-color: var(--color_grey_800);
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  box-shadow: 0px -10px 8px 0px rgba(0, 0, 0, 0.11);
}

.CheckPanelFooter_overlayContent {
  padding: 1rem;
}

.CheckPanelFooter_checkDetailsMore {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.CheckPanelFooter_checkDetailsMoreGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.CheckPanelFooter_checkDetailsMoreCloseBtn {
  padding: 1rem;
  align-self: flex-end;
}