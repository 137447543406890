.GlobalSearchHeader_root {
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 4rem;
}
.GlobalSearchHeader_root.GlobalSearchHeader_active .GlobalSearchHeader_search_field_container {
  width: unset;
  right: 0;
}
.GlobalSearchHeader_root .GlobalSearchHeader_search_field_container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 4.4rem;
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden;
}
.GlobalSearchHeader_root .GlobalSearchHeader_search_field_container .FormFieldText_adorn_start {
  padding: 0;
}