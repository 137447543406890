.ModifierCardButton_root {
  position: relative;
  display: flex;
  flex-direction: row;
}
.ModifierCardButton_root.ModifierCardButton_is_selected .ModifierCardButton_btn_main {
  border: 1px solid var(--color_bg_main);
  background-color: var(--color_bg_tertiary);
}
.ModifierCardButton_root.ModifierCardButton_show_btn_quantity .ModifierCardButton_btn_main {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right-color: transparent;
  min-width: 26rem;
  max-width: calc(25% - 6rem);
}
.ModifierCardButton_root.ModifierCardButton_show_btn_quantity .ModifierCardButton_btn_quantity_wrapper {
  display: flex;
}
.ModifierCardButton_root.ModifierCardButton_is_disabled {
  opacity: 0.5;
  pointer-events: none;
}
.ModifierCardButton_root.ModifierCardButton_hide_icon_check .ModifierCardButton_icon_check {
  display: none;
}
.ModifierCardButton_root.ModifierCardButton_hide_icon_error .ModifierCardButton_icon_error {
  display: none;
}
.ModifierCardButton_root.ModifierCardButton_hide_icon_next .ModifierCardButton_icon_next {
  display: none;
}
.ModifierCardButton_root.ModifierCardButton_hide_command .ModifierCardButton_command {
  display: none;
}
.ModifierCardButton_root.ModifierCardButton_hide_price_diff .ModifierCardButton_price_diff {
  display: none;
}
.ModifierCardButton_root.ModifierCardButton_hide_quantity_count .ModifierCardButton_quantity_count {
  display: none;
}

.ModifierCardButton_btn_main {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0;
  gap: 0.25rem;
  outline: 0;
  margin: 0;
  padding: 1rem;
  text-align: left;
  user-select: none;
  vertical-align: middle;
  -webkit-appearance: none;
  text-decoration: none;
  border-radius: 0.5rem;
  min-width: 32rem;
  max-width: 25%;
  height: 6rem;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-radius 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: var(--color_text_primary);
  border: 1px solid transparent;
  background-color: var(--color_bg_tertiary_lite);
  position: relative;
}
@keyframes ModifierCardButton_pulse {
  0% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.1;
  }
}
.ModifierCardButton_btn_main::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  left: 0;
  top: 0;
  border-radius: inherit;
  background-color: white;
  user-select: none;
  pointer-events: none;
}
.ModifierCardButton_btn_main:active::before {
  animation: ModifierCardButton_pulse 0.75s;
}

.ModifierCardButton_name {
  flex-grow: 1;
}

.ModifierCardButton_command {
  position: absolute;
  top: -0.75rem;
  left: -0.75rem;
}

.ModifierCardButton_btn_quantity_wrapper {
  display: none;
  position: relative;
  flex-direction: row;
  align-items: center;
  flex: 0;
}

.ModifierCardButton_btn_quantity {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  outline: 0;
  margin: 0;
  padding: 1rem;
  user-select: none;
  vertical-align: middle;
  -webkit-appearance: none;
  text-decoration: none;
  border-radius: 0 0.5rem 0.5rem 0;
  width: 6rem;
  height: 6rem;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-radius 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: var(--color_text_primary);
  border: 1px solid var(--color_bg_main);
  background-color: var(--color_bg_tertiary);
}