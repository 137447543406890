.DataTableSortButton_root {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: var(--color_text_secondary);
  background-color: transparent;
  /* Body/Regular */
  font-style: normal;
  /* Body/Regular */
  font-size: 2rem; /* 20px */
  line-height: 140%; /* 28px */
  /* Body/SemiBold */
  font-weight: 700;
  padding: 0;
}
.DataTableSortButton_root.DataTableSortButton_active {
  color: var(--color_text_primary);
}

.DataTableSortButton_label {
  text-align: left;
}

.DataTableSortButton_icon {
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  opacity: 0;
}
.DataTableSortButton_icon.DataTableSortButton_icon-visible {
  opacity: 1;
}