.CheckPageVoided_root {
  height: 100%;
  padding: 1rem;
}

.CheckPageVoided_activity-root {
  height: 70%;
  padding-bottom: 5rem;
}

.CheckPageVoided_activity {
  height: 70rem;
  overflow-y: scroll;
}