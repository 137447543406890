.FormFieldPinOverlay_root {
  display: grid;
}

.FormFieldPinOverlay_status {
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.25);
  justify-self: center;
  align-self: center;
}
.FormFieldPinOverlay_status.FormFieldPinOverlay_entered {
  border: 0.2rem solid var(--color_text_primary);
}
.FormFieldPinOverlay_status.FormFieldPinOverlay_error {
  border: 0.2rem solid var(--color_error_main);
  background: var(--color_error_main);
}