.EODModal_eodHeader {
  padding: 0 0 2rem;
  align-items: center;
  border-bottom: 1px solid #111111;
}

.EODModal_dateSelector {
  background-color: transparent;
  color: #FFF;
  font-size: 20px;
  border: 0;
  font-family: "Sofia-Pro";
}

.EODModal_container {
  background-color: var(--color_grey_800);
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
}
.EODModal_container .EODModal_contentLeft {
  position: sticky;
  display: flex;
  padding-top: 2rem;
  overflow: visible;
  min-width: 15%;
  flex-direction: column;
  gap: 2rem;
  border-right: 1px solid #111111;
  margin-left: 2rem;
}
.EODModal_container .EODModal_contentLeft > * {
  padding-bottom: 2rem;
  border-bottom: 1px solid #111111;
}
.EODModal_container .EODModal_contentRight {
  overflow-y: scroll;
  width: 100%;
  padding: 2rem;
}
.EODModal_container .EODModal_contentRight > * {
  padding: 0.5rem;
}
.EODModal_container .EODModal_contentRight ::after {
  border: 0;
}