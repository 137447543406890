.ModifierBreadCrumbButton_root {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
  outline: 0;
  margin: 0;
  padding: 1rem;
  text-align: left;
  user-select: none;
  vertical-align: middle;
  -webkit-appearance: none;
  text-decoration: none;
  border-radius: 0.5rem;
  min-width: 16rem;
  min-height: 6rem;
  border: 1px solid transparent;
  background-color: transparent;
  touch-action: none;
  position: relative;
}
.ModifierBreadCrumbButton_root .ModifierBreadCrumbButton_name, .ModifierBreadCrumbButton_root .ModifierBreadCrumbButton_message {
  /* Body/Regular */
  font-style: normal;
  /* BodyLG */
  font-size: 2.4rem; /* 24px */
  line-height: 140%; /* 33.6px */
  /* Body/Medium */
  font-weight: 500;
  user-select: none;
}
@keyframes ModifierBreadCrumbButton_pulse {
  0% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.1;
  }
}
.ModifierBreadCrumbButton_root::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  left: 0;
  top: 0;
  border-radius: inherit;
  background-color: white;
  user-select: none;
  pointer-events: none;
}
.ModifierBreadCrumbButton_root:active::before {
  animation: ModifierBreadCrumbButton_pulse 0.75s;
}