.ModalHeader_root {
  display: flex;
  padding: 4rem 4.8rem 0 8.8rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.8rem;
  color: var(--color_text_primary);
}

.ModalHeader_children {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  color: inherit;
}

.ModalHeader_closeButton {
  flex: 0 0 auto;
  color: inherit;
}