.CheckTransferPage_page-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.CheckTransferPage_instruction {
  padding: 1rem;
}

.CheckTransferPage_form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 100%;
  gap: 1rem;
  padding-bottom: 1rem;
}

.CheckTransferPage_list {
  height: 100%;
  overflow: auto;
}