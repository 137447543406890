.FormFieldLabel_root {
  display: block;
  text-align: left;
  max-width: 100%;
  user-select: none;
  color: var(--color_text_secondary);
  /* Body/Regular */
  font-style: normal;
  /* Body/Regular */
  font-size: 2rem; /* 20px */
  line-height: 140%; /* 28px */
  /* Body/Regular */
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  transform-origin: top left;
  transform: translate(0, 0) scale(1);
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.FormFieldLabel_root.FormFieldLabel_required::after {
  content: " *";
  display: inline;
}
.FormFieldLabel_root.FormFieldLabel_error {
  color: var(--color_error_main);
}
.FormFieldLabel_root.FormFieldLabel_disabled {
  opacity: 0.5;
}
.FormFieldLabel_root.FormFieldLabel_size_sm {
  /* Body/Regular */
  font-style: normal;
  /* BodySM */
  font-size: 1.6rem; /* 16px */
  line-height: 140%; /* 22.4px */
  /* Body/Regular */
  font-weight: 400;
}
.FormFieldLabel_root.FormFieldLabel_size_md {
  /* Body/Regular */
  font-style: normal;
  /* Body/Regular */
  font-size: 2rem; /* 20px */
  line-height: 140%; /* 28px */
  /* Body/Regular */
  font-weight: 400;
}