.PrintFailureNotificationGroup_grouped div:nth-of-type(1) {
  z-index: 3;
}
.PrintFailureNotificationGroup_grouped div:nth-of-type(2) {
  z-index: 2;
  transform: scale3d(0.97, 0.97, 1) translateY(-80%);
}
.PrintFailureNotificationGroup_grouped div:nth-of-type(3) {
  z-index: 1;
  transform: scale3d(0.94, 0.94, 1) translateY(-165%);
}
.PrintFailureNotificationGroup_grouped div:nth-of-type(4) {
  transform: scale3d(0.91, 0.91, 1) translateY(-260%);
}
.PrintFailureNotificationGroup_grouped div:nth-of-type(n+5) {
  display: none;
}
.PrintFailureNotificationGroup_grouped .PrintFailureNotificationGroup_notification {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.PrintFailureNotificationGroup_expanded {
  align-items: flex-end;
}
.PrintFailureNotificationGroup_expanded div:nth-of-type(n+2) {
  width: calc(100% - 5rem);
}

.PrintFailureNotificationGroup_notification {
  padding: 3rem;
}

.PrintFailureNotificationGroup_message {
  word-break: break-word;
  flex-grow: 1;
  color: var(--color_text_secondary);
}

.PrintFailureNotificationGroup_time {
  width: 6rem;
  color: var(--color_text_secondary);
}

.PrintFailureNotificationGroup_icon {
  font-size: 2rem;
  width: 3rem;
  line-height: 3rem;
  text-align: center;
  border-radius: 5rem;
}