.CheckPageSplit_root .CheckItemButton_multi_select_button {
  width: 8rem;
  justify-content: left;
}

.CheckPageSplit_card {
  display: grid;
  grid-template-rows: auto minmax(0, 1fr) auto;
  border: 1px solid var(--color_grey_500);
  background-color: var(--color_bg_secondary);
  transition: border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  height: 100%;
  overflow: hidden;
  border-radius: 0.5rem;
}

.CheckPageSplit_card-selected {
  border: 2px solid var(--color_divider);
}
.CheckPageSplit_card-selected .CheckPageSplit_items > * {
  border-left-color: transparent;
  border-right-color: transparent;
}

.CheckPageSplit_placeholder-chk {
  border: none;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%234D4E50' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
.CheckPageSplit_placeholder-chk .CheckPageSplit_card-title {
  border-bottom: none;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3cline x1='0' y1='100%25' x2='100%25' y2='100%25' fill='none' rx='4' ry='4' stroke='%230C141B' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.CheckPageSplit_card-footer {
  min-height: 4rem;
  padding: 1.6rem;
  border-top: 1px solid var(--color_bg_main);
  box-shadow: 0px -6px 16px black;
}

.CheckPageSplit_card-title {
  padding: 1.6rem;
  border-bottom: 1px solid var(--color_bg_main);
}

.CheckPageSplit_items {
  flex: 1 1 auto;
  overflow-y: scroll;
}
.CheckPageSplit_items.CheckPageSplit_has-add-btn {
  text-align: center;
  align-content: center;
}

.CheckPageSplit_resetBtn {
  color: var(--color_primary_main);
}

.CheckPageSplit_page-main {
  padding-left: 6rem;
}