.LoyaltyCustomerOnboardTool_form {
  margin-top: 2rem;
}

.LoyaltyCustomerOnboardTool_birthMonthDay {
  gap: 1rem;
  align-items: center;
}
.LoyaltyCustomerOnboardTool_birthMonthDay .LoyaltyCustomerOnboardTool_select {
  width: 12rem;
}