.GiftCardAddToCheckModal_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.GiftCardAddToCheckModal_currency-input {
  display: flex;
  justify-self: center;
}