.CheckItemScheduleModal_form {
  grid-area: frm;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.CheckItemScheduleModal_switch-wrapper {
  grid-area: swc;
  height: 100%;
}

.CheckItemScheduleModal_switch {
  position: fixed;
  top: 28%;
}

.CheckItemScheduleModal_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: ". frm swc";
  align-items: center;
  height: 100%;
}