.CheckItemSeatSelectionCTA_textIcon {
  /* Body/Regular */
  font-style: normal;
  /* Body/Regular */
  font-size: 2rem; /* 20px */
  line-height: 140%; /* 28px */
  /* Body/Regular */
  font-weight: 400;
  pointer-events: none;
  user-select: none;
}