.MenuItemGroupButtons_root {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.MenuItemGroupButtons_root .MenuItemGroupButtons_scrollContainer {
  width: 100%;
  height: 100%;
  display: grid;
  gap: 1rem;
  overflow: auto;
}

.MenuItemGroupButtons_favorite_button {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.MenuItemGroupButtons_favorite_button .IconCTA_icon {
  width: 7rem;
  height: 7rem;
}

.MenuItemGroupButtons_favorite_button_icon {
  color: var(--color_menu_fave);
}