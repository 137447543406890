.CheckItemSeatSelection_optionsScrollContainer {
  overflow-x: auto;
  overflow-y: hidden;
  flex-grow: 1;
}

.CheckItemSeatSelection_optionsContainer {
  display: inline-flex;
  flex-direction: row;
  gap: 1rem;
}