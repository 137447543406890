.SettingsPrinters_root {
  padding: 1rem;
}

.SettingsPrinters_icon_online {
  color: var(--color_success_main);
}

.SettingsPrinters_icon_offline {
  color: var(--color_error_main);
}

.SettingsPrinters_table_container {
  height: 65vh;
  width: 100%;
  overflow: auto;
}