.CheckItemPageNewEdit_main_root {
  position: relative;
  width: calc(100% + 1.5rem);
  margin-left: -1.5rem;
  height: 100%;
}

.CheckItemPageNewEdit_main_container {
  width: 100%;
  height: 100%;
}

.CheckItemPageNewEdit_main_buttons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-right: 1px solid var(--color_transparent_dark_50);
  width: 30rem;
  height: calc(100% - 6rem);
  overflow-y: auto;
}

.CheckItemPageNewEdit_main_cardScrollContainer {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.CheckItemPageNewEdit_main_cardsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
}