.ModifierCard_root {
  position: relative;
  background-color: transparent;
  border-radius: 0.5rem;
  padding: 2rem;
  width: 100%;
  gap: 1rem;
}

.ModifierCard_button_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 1rem;
}