@import '/home/runner/work/pos/pos/src/styles/sass/_globals.scss';
:root {
  // global var "$color-map" from "_colors.scss" file
  @include spread-map-to-css-vars($color-map);
}

// global changes to all rem values based on screen size
html {
  font-size: 6px;
}

@include breakpoints-t3-up('sm') {
  html {
    font-size: 7px;
  }
}
@include breakpoints-t3-up('md') {
  html {
    font-size: 8px;
  }
}
@include breakpoints-t3-up('lg') {
  html {
    font-size: 9px;
  }
}
@include breakpoints-t3-up('xl') {
  html {
    font-size: 10px;
  }
}

