.SelectOption_root {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  min-height: 8rem;
  padding: 0.5rem 1.5rem;
  gap: 1rem;
  position: relative;
}
@keyframes SelectOption_pulse {
  0% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.1;
  }
}
.SelectOption_root::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  left: 0;
  top: 0;
  border-radius: inherit;
  background-color: white;
  user-select: none;
  pointer-events: none;
}
.SelectOption_root:active::before {
  animation: SelectOption_pulse 0.75s;
}

.SelectOption_selected {
  background-color: var(--color_bg_secondary_lite);
}

.SelectOption_disabled {
  opacity: 0.5;
}

.SelectOption_multipleIcon {
  padding: 0;
}

.SelectOption_adornStart {
  padding: 1rem 0;
}

.SelectOption_adornEnd {
  padding: 1rem 0;
}