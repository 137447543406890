.PageMainHeader_root {
  display: grid;
  grid-template-rows: 1fr;
  height: 100%;
  padding: 0 2rem 0;
  border-bottom-width: 1px;
}
.PageMainHeader_root.PageMainHeader_has_start {
  grid-template-columns: minmax(0, 1fr) auto;
}
.PageMainHeader_root.PageMainHeader_has_border_bottom {
  border-bottom-color: var(--color_divider);
  border-bottom-style: solid;
}
.PageMainHeader_root .PageMainHeader_start {
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  align-items: center;
  flex-direction: row;
}
.PageMainHeader_root .PageMainHeader_end {
  display: flex;
  padding: 0.5rem;
  align-items: center;
  flex-direction: row;
  justify-content: end;
}