.EndOfDayContent_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 0;
}

.EndOfDayContent_centerText {
  align-self: center;
}

.EndOfDayContent_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem 0;
  gap: 1rem;
}

.EndOfDayContent_contentRow {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
  gap: 1rem;
  align-items: start;
  border-bottom: 1px solid #111111;
  padding-bottom: 1rem;
}
@media (max-width: 600px) {
  .EndOfDayContent_contentRow {
    grid-template-columns: 1fr;
  }
  .EndOfDayContent_contentRow :last-child {
    justify-self: unset;
    width: unset;
  }
}
.EndOfDayContent_contentRow :last-child {
  justify-self: flex-end;
  width: fit-content;
}

.EndOfDayContent_reportLineRow {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.EndOfDayContent_reportBackground {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--color_grey_900);
  padding: 3rem;
}
.EndOfDayContent_reportBackground :last-child {
  border-top: 1px solid var(--color_grey_800);
}

.EndOfDayContent_errorMsg {
  text-align: center;
  background-color: var(--color_grey_900);
  border-radius: 8px;
  padding: 0.5rem;
}

.EndOfDayContent_stubbyDivider {
  margin: 1rem 0 -1rem !important;
  margin-bottom: -1rem;
  margin-left: 0.5rem;
  margin-top: 2rem;
}