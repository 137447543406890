.ExpansionSearch_container {
  border: 1px solid var(--color_text_secondary);
  min-width: min-content;
  border-radius: 8rem;
  display: inline-flex;
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.ExpansionSearch_container.ExpansionSearch_collapsed {
  width: 0;
}
.ExpansionSearch_container.ExpansionSearch_expanded {
  width: 100%;
  background-color: var(--color_grey_900);
}

.ExpansionSearch_hidden {
  display: none;
}