.TableCell_root {
  display: table-cell;
  vertical-align: inherit;
  padding: 1.6rem;
  border-bottom: none;
  position: relative;
}

.TableCell_alignInherit {
  text-align: inherit;
}

.TableCell_alignLeft {
  text-align: left;
}

.TableCell_alignCenter {
  text-align: left;
}

.TableCell_alignRight {
  text-align: right;
}

.TableCell_alignJustify {
  text-align: justify;
}