.CheckItemButton_root {
  position: relative;
  user-select: none;
  outline: none;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  border-bottom: 1px solid var(--color_bg_main);
  opacity: 1;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.CheckItemButton_root.CheckItemButton_is-sent {
  opacity: 0.5;
}
@keyframes CheckItemButton_pulse {
  0% {
    opacity: 0.5;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
.CheckItemButton_root.CheckItemButton_is-clickable .CheckItemButton_multi_select_button {
  position: relative;
}
@keyframes CheckItemButton_pulse {
  0% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.1;
  }
}
.CheckItemButton_root.CheckItemButton_is-clickable .CheckItemButton_multi_select_button::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  left: 0;
  top: 0;
  border-radius: inherit;
  background-color: white;
  user-select: none;
  pointer-events: none;
}
.CheckItemButton_root.CheckItemButton_is-clickable .CheckItemButton_multi_select_button:active::before {
  animation: CheckItemButton_pulse 0.75s;
}
.CheckItemButton_root.CheckItemButton_is-clickable .CheckItemButton_single_select_button {
  position: relative;
}
@keyframes CheckItemButton_pulse {
  0% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.1;
  }
}
.CheckItemButton_root.CheckItemButton_is-clickable .CheckItemButton_single_select_button::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  left: 0;
  top: 0;
  border-radius: inherit;
  background-color: white;
  user-select: none;
  pointer-events: none;
}
.CheckItemButton_root.CheckItemButton_is-clickable .CheckItemButton_single_select_button:active::before {
  animation: CheckItemButton_pulse 0.75s;
}
.CheckItemButton_root.CheckItemButton_is-loading {
  animation: CheckItemButton_pulse 2s infinite;
}
.CheckItemButton_root.CheckItemButton_is-some-selected .CheckItemButton_multi_select_icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.CheckItemButton_root.CheckItemButton_is-some-selected.CheckItemButton_is-not-selected {
  opacity: 0.5;
  background-color: var(--color_transparent_dark_50);
}
.CheckItemButton_root.CheckItemButton_is-some-selected.CheckItemButton_is-not-selected .CheckItemButton_multi_select_icon {
  background-color: transparent;
}
.CheckItemButton_root.CheckItemButton_is-some-selected.CheckItemButton_is-not-selected.CheckItemButton_is-not-sent {
  opacity: 1;
}
.CheckItemButton_root.CheckItemButton_is-some-selected.CheckItemButton_is-selected {
  opacity: 1;
}
.CheckItemButton_root.CheckItemButton_is-some-selected.CheckItemButton_is-selected .CheckItemButton_multi_select_icon {
  background-color: var(--color_success_main);
}

.CheckItemButton_multi_select_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  min-width: 5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  align-self: stretch;
  position: relative;
}
.CheckItemButton_multi_select_button .CheckItemButton_multi_select_icon {
  width: 1rem;
  height: 1rem;
  border-radius: 0.25rem;
  border: 1px solid var(--color_divider);
  background-color: transparent;
  transition: border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: none;
}

.CheckItemButton_single_select_button {
  width: 100%;
  text-align: left;
  display: grid;
  gap: 0.1rem;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 2rem;
  align-self: stretch;
  position: relative;
}

.CheckItemButton_text {
  user-select: none;
  pointer-events: none;
}

.CheckItemButton_fill_columns {
  grid-column: 1/-1;
}

.CheckItemButton_corner_triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 3rem 3rem;
  border-color: transparent transparent transparent var(--color_warn_main);
  left: 0;
  top: 0;
  position: absolute;
}