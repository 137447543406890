.S2PCheckPaidNotification_root {
  padding: 3rem;
}

.S2PCheckPaidNotification_message {
  word-break: break-word;
  flex-grow: 1;
}

.S2PCheckPaidNotification_time {
  width: 6rem;
  color: var(--color_text_secondary);
}

.S2PCheckPaidNotification_icon {
  font-size: 2rem;
  width: 3rem;
  line-height: 3rem;
  text-align: center;
  border-radius: 5rem;
}