.BackDrop_root {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
  z-index: -1;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  opacity: 0;
}
.BackDrop_root.BackDrop_open {
  opacity: 1;
}
.BackDrop_root.BackDrop_hidden {
  opacity: 0;
}