.FormKeyboard_root .hg-theme-default {
  font-family: unset;
  background-color: transparent;
}
.FormKeyboard_root .hg-theme-default .hg-button {
  border-bottom: unset;
  background-color: var(--color_grey_900);
}
.FormKeyboard_root .hg-theme-default .hg-button span {
  color: var(--color_text_primary);
}

.FormKeyboard_overlay {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  padding: 0 1rem 1rem 1rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.FormKeyboard_inline {
  width: 100%;
  padding: 1rem;
}

.FormKeyboard_theme-round-keys .hg-theme-default .hg-rows {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.FormKeyboard_theme-round-keys .hg-theme-default .hg-row {
  width: fit-content;
}
.FormKeyboard_theme-round-keys .hg-theme-default .hg-button {
  border-radius: 50%;
  font-size: 3rem;
  width: 10rem;
  height: 10rem;
  padding: 0;
  box-sizing: unset;
}
.FormKeyboard_theme-round-keys .hg-theme-default .hg-button.hg-standardBtn {
  font-size: 3rem;
  width: 10rem;
  height: 10rem;
  padding: 0;
  box-sizing: unset;
}

.FormKeyboard_theme-match-button-width .hg-theme-default .hg-button {
  width: 2rem;
}

.FormKeyboard_not-open {
  display: none;
}