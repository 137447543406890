.ModifierCommandPalette_btn {
  touch-action: none;
}

.ModifierCommandPalette_container {
  transition: bottom 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  position: absolute;
  padding: 1rem;
  height: 6rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  bottom: -8rem;
  gap: 1rem;
}

.ModifierCommandPalette_containerShow {
  bottom: -1rem;
}

.ModifierCommandPalette_container_cmdOptions {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: var(--color_bg_tertiary);
  border-top: 1px solid var(--color_transparent_dark_50);
}

.ModifierCommandPalette_containerLongPress {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: var(--color_bg_tertiary);
  border-top: 1px solid var(--color_transparent_dark_50);
}