.CheckItemSplitModal_container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}

.CheckItemSplitModal_itemName {
  font-style: italic;
}

.CheckItemSplitModal_partsLine {
  /* Body/Regular */
  font-style: normal;
  /* BodyLG */
  font-size: 2.4rem; /* 24px */
  line-height: 140%; /* 33.6px */
  /* Body/SemiBold */
  font-weight: 700;
}
.CheckItemSplitModal_partsLine .CheckItemSplitModal_value {
  border-bottom: 1px solid var(--color_grey_300);
  display: inline-block;
  min-width: 9rem;
  text-align: center;
}