.PageMain_root {
  height: 100%;
}
.PageMain_root.PageMain_has_header .PageMain_header {
  height: 10rem;
}
.PageMain_root.PageMain_has_header .PageMain_main {
  height: calc(100% - 10rem);
}

.PageMain_main {
  padding: 0.5rem;
  height: 100%;
}