.SGFloorPlan_editorRoot {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: minmax(60vw, 1fr) auto;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}

.SGFloorPlan_editorHeader {
  grid-column: 1/3;
}

.SGFloorPlan_grid {
  display: grid;
  grid-template-columns: minmax(80vw, 1fr) auto;
}