.CardReaderStatus_popOver {
  margin-top: 1rem;
}
.CardReaderStatus_popOver .PopOver_paper {
  margin-right: 5rem;
}

.CardReaderStatus_popOverContent {
  padding: 3.2rem 2.4rem;
  width: 100%;
  min-width: 32vw;
  max-width: 100%;
}