.CTAKeys_root {
  display: inline-grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.CTAKeys_root.CTAKeys_sizeXl {
  padding-top: 1rem;
}
.CTAKeys_root.CTAKeys_sizeXl .CTAKeys_keyIcon .FontIcon_root {
  font-size: 5rem;
}

.CTAKeys_keyLabel {
  font-size: 3rem;
}