.CardReaderDetails_root {
  padding: 1rem;
}

.CardReaderDetails_name {
  width: 100%;
}

.CardReaderDetails_serial {
  width: 100%;
}

.CardReaderDetails_battery-percent {
  width: 10rem;
}

.CardReaderDetails_connected-icon {
  width: 4rem;
  height: 4rem;
}

.CardReaderDetails_battery-icon {
  width: 4rem;
  height: 4rem;
}

.CardReaderDetails_show-background {
  background-color: var(--color_grey_900);
  border-radius: 5px;
}

.CardReaderDetails_color_error {
  color: var(--color_error_main);
}

.CardReaderDetails_color_success {
  color: var(--color_success_main);
}

.CardReaderDetails_color-warn-light {
  color: var(--color_warn_lite);
}

.CardReaderDetails_color_warn_main {
  color: var(--color_warn_main);
}

.CardReaderDetails_color-default {
  color: var(--color_grey_500);
}