.EnterToken_input-title::after {
  content: " *";
  display: inline;
}

.EnterToken_form {
  width: max(40vw, 30rem);
}

.EnterToken_input {
  text-align: center;
  text-transform: uppercase;
}