.FormFieldSearch_root {
  justify-content: center;
}
.FormFieldSearch_root .FormFieldText_content {
  border-radius: 10rem;
  background-color: var(--color_transparent_lite_25);
  align-items: center;
}
.FormFieldSearch_root.FormFieldSearch_small .FormFieldInput_root {
  padding: 0 1rem;
}
.FormFieldSearch_root.FormFieldSearch_small .FormFieldText_adorn_start {
  padding: 0;
}
.FormFieldSearch_root.FormFieldSearch_small .FormFieldText_adorn_end {
  padding: 0;
}
.FormFieldSearch_root.FormFieldSearch_medium .FormFieldText_content {
  padding: 0 1rem;
}
.FormFieldSearch_root.FormFieldSearch_medium .FormFieldText_adorn_start {
  padding: 0;
}
.FormFieldSearch_root.FormFieldSearch_medium .FormFieldText_adorn_end {
  padding: 0;
}

.FormFieldSearch_input {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.FormFieldSearch_icon {
  fill: var(--color_grey_500);
}

.FormFieldSearch_icon-hidden {
  display: none;
}

.FormFieldSearch_icon-focused {
  fill: var(--color_grey_200);
}