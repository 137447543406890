.PopOver_root {
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  opacity: 0;
  z-index: 1010;
  max-width: 100%;
  max-height: 100%;
}
.PopOver_root.PopOver_open {
  opacity: 1;
}
.PopOver_root.PopOver_open .PopOver_paper {
  opacity: 1;
  transform: scale(1);
}

.PopOver_paper {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: var(--color_bg_secondary);
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, top 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, bottom 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, right 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transform: scale(0.3);
  opacity: 0;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  max-width: calc(100% - 5rem);
  max-height: calc(100% - 5rem);
}