.CheckItemDetailNote_root {
  padding: 2rem;
}

.CheckItemDetailNote_headingRoot {
  display: flex;
  gap: 0.25rem;
  padding: 1rem;
  height: 6.8rem;
  align-items: center;
}

.CheckItemDetailNote_headingText {
  /* Body/Regular */
  font-style: normal;
  /* BodyLG */
  font-size: 2.4rem; /* 24px */
  line-height: 140%; /* 33.6px */
  /* Body/SemiBold */
  font-weight: 700;
}

.CheckItemDetailNote_input {
  touch-action: none;
  field-sizing: content;
}