.FormFieldCheckBox_content {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  position: relative;
}

.FormFieldCheckBox_labelStart {
  flex-direction: row-reverse;
}

.FormFieldCheckBox_sizeSm .FormFieldCheckBox_input {
  width: 2rem;
  height: 2rem;
}

.FormFieldCheckBox_sizeMd .FormFieldCheckBox_input {
  width: 3rem;
  height: 3rem;
}

.FormFieldCheckBox_input {
  opacity: 0 !important;
}

.FormFieldCheckBox_inputIcon {
  position: absolute;
  user-select: none;
  pointer-events: none;
}