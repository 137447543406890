.FormFieldToggle_root > *:not(:last-child) {
  margin-bottom: 0.5rem;
}
.FormFieldToggle_root .CTA_label {
  text-align: left;
  width: 100%;
}
.FormFieldToggle_root.FormFieldToggle_size_sm .FormFieldToggle_title {
  /* Body/Regular */
  font-style: normal;
  /* BodySM */
  font-size: 1.6rem; /* 16px */
  line-height: 140%; /* 22.4px */
  /* Body/Regular */
  font-weight: 400;
}
.FormFieldToggle_root.FormFieldToggle_size_md .FormFieldToggle_title {
  /* Body/Regular */
  font-style: normal;
  /* Body/Regular */
  font-size: 2rem; /* 20px */
  line-height: 140%; /* 28px */
  /* Body/Regular */
  font-weight: 400;
}

.FormFieldToggle_title {
  background-color: var(--color_grey_800);
  text-transform: uppercase;
  padding: 1.5rem;
}

.FormFieldToggle_select-all {
  padding: 1.5rem 0 1rem 2.5rem;
}

.FormFieldToggle_list .FormFieldToggle_option {
  width: 100%;
  background-color: transparent;
  border: 2px solid transparent;
}
.FormFieldToggle_list .FormFieldToggle_option.CTA_selected {
  border: 2px solid var(--color_divider);
}
.FormFieldToggle_list .FormFieldToggle_option:not(:last-child) {
  margin-bottom: 0.5rem;
}

.FormFieldToggle_list-with-buttons {
  display: flex;
  flex-wrap: wrap;
}
.FormFieldToggle_list-with-buttons .FormFieldToggle_option {
  display: inline-flex;
  width: unset;
  margin-top: 1rem;
  margin-right: 1rem;
}

.FormFieldToggle_list-with-button-group {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
}
.FormFieldToggle_list-with-button-group .FormFieldToggle_option {
  display: inline-flex;
  width: unset;
  margin: 0;
  border-radius: 0;
  text-align: center;
  min-width: unset;
}
.FormFieldToggle_list-with-button-group .FormFieldToggle_option:first-of-type {
  border-radius: 5rem 0 0 5rem;
}
.FormFieldToggle_list-with-button-group .FormFieldToggle_option:last-of-type {
  border-radius: 0 5rem 5rem 0;
}

.FormFieldToggle_option-description {
  margin-top: 1rem;
}

.FormFieldToggle_full_height {
  height: 100%;
}
.FormFieldToggle_full_height .FormFieldToggle_list {
  max-height: 50rem;
  overflow: auto;
}
.FormFieldToggle_full_height .FormFieldToggle_list-with-buttons {
  max-height: 50rem;
  overflow: auto;
}