.NotificationTray_page-root {
  height: 100%;
}

.NotificationTray_modal-title {
  padding: 3rem;
}

.NotificationTray_modal-title-headline {
  flex-grow: 1;
  text-align: left;
}

.NotificationTray_no-notification-message {
  align-self: center;
}

.NotificationTray_notification-list {
  overflow: auto;
  height: 100%;
}

.NotificationTray_notification {
  background: var(--color_grey_900);
  border-radius: 1rem;
}