.TableRow_root {
  color: inherit;
  display: table-row;
  vertical-align: middle;
  outline: 0;
  background-color: var(--color_grey_900);
}

.TableRow_selected {
  background-color: var(--color_grey_800);
}