.StyleGuidePage_root.Page_root {
  padding: 0;
  border-left: none;
  height: calc(100vh - 300px);
}
.StyleGuidePage_root .Page_panel {
  overflow-y: auto;
  height: 100%;
}

.StyleGuidePage_sgsRoot {
  padding: 0;
  border-left: none;
}

.StyleGuidePage_sgsComponent {
  padding: 0;
}