.MenuItemGroupButton_root {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  outline: 0;
  margin: 0;
  padding: 1rem 0.5rem;
  gap: 1rem;
  user-select: none;
  vertical-align: middle;
  -webkit-appearance: none;
  text-decoration: none;
  min-width: 16.5rem;
  text-transform: capitalize;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: var(--color_text_primary);
  background-color: var(--color_bg_secondary);
  border: 1px solid transparent;
  border-radius: 0.5rem;
  position: relative;
}
@keyframes MenuItemGroupButton_pulse {
  0% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.1;
  }
}
.MenuItemGroupButton_root::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  left: 0;
  top: 0;
  border-radius: inherit;
  background-color: white;
  user-select: none;
  pointer-events: none;
}
.MenuItemGroupButton_root:active::before {
  animation: MenuItemGroupButton_pulse 0.75s;
}

.MenuItemGroupButton_label {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  color: var(--color_text_primary);
  display: -webkit-box;
  /* Body/Regular */
  font-style: normal;
  /* Body/Regular */
  font-size: 2rem; /* 20px */
  line-height: 140%; /* 28px */
  /* Body/Regular */
  font-weight: 400;
  max-width: 100%;
  overflow: hidden;
  width: 100%;
  margin-left: 0.4rem;
  text-align: left;
}

.MenuItemGroupButton_selected {
  background-color: var(--color_bg_secondary_lite);
}

.MenuItemGroupButton_disabled {
  opacity: 0.5;
}

.MenuItemGroupButton_icon-wrapper {
  position: relative;
  width: 0.75rem;
  height: 80%;
  min-height: 1rem;
}

.MenuItemGroupButton_icon {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: var(--color_divider);
}

.MenuItemGroupButton_icon_disabled {
  opacity: 0.4;
}