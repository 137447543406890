.ProvisionPage_content {
  height: 100%;
}

.ProvisionPage_content_header {
  height: 4rem;
}

.ProvisionPage_content_main {
  height: calc(100% - 11rem);
  overflow-y: auto;
}

.ProvisionPage_content_footer {
  height: 7rem;
  background-color: var(--color_bg_main);
}