.FormFieldSelect_root {
  position: relative;
  display: inline-flex;
}
.FormFieldSelect_root.FormFieldSelect_required .FormFieldSelect_label::after {
  content: " *";
  display: inline;
}
.FormFieldSelect_root.FormFieldSelect_disabled .FormFieldSelect_content {
  opacity: 0.5;
}
.FormFieldSelect_root.FormFieldSelect_error .FormFieldSelect_label {
  color: var(--color_error_main);
}

.FormFieldSelect_message {
  left: 0;
}

.FormFieldSelect_content {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  gap: 0.25rem;
  max-width: 100%;
}

.FormFieldSelect_contentStack {
  display: inline-flex;
  flex-direction: column;
  gap: 0.25rem;
  max-width: 100%;
}

.FormFieldSelect_inputRow {
  display: inline-flex;
  flex-direction: row;
  gap: 0.25rem;
  align-items: center;
  max-width: 100%;
  padding-right: 4rem;
  padding-left: 1.5rem;
  position: relative;
}

.FormFieldSelect_inputWrapper {
  flex: 1 1 auto;
  max-width: 100%;
}

.FormFieldSelect_input {
  width: 100%;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.FormFieldSelect_inputNative {
  bottom: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  pointer-events: none;
  width: 100%;
  box-sizing: border-box;
}

.FormFieldSelect_label {
  display: block;
  text-align: left;
  width: 100%;
  max-width: 100%;
  user-select: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  transform-origin: top left;
  transform: translate(0, 0) scale(1);
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.FormFieldSelect_list {
  display: inline-flex;
  flex-direction: column;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: var(--color_bg_secondary);
  max-height: calc(100% - 5rem);
  overflow-y: auto;
}

.FormFieldSelect_adornEnd {
  position: absolute;
  right: 0;
}

.FormFieldSelect_variantStandard .FormFieldSelect_label {
  color: var(--color_text_primary);
}
.FormFieldSelect_variantStandard .FormFieldSelect_inputRow {
  padding-left: 1.5rem;
  border-radius: 0.5rem;
  background-color: var(--color_bg_secondary);
}

.FormFieldSelect_variantOutlined .FormFieldSelect_label {
  color: var(--color_text_primary);
}
.FormFieldSelect_variantOutlined .FormFieldSelect_inputRow {
  padding-left: 1.5rem;
  border-radius: 0.5rem;
  background-color: transparent;
  border: 2px solid transparent;
  overflow: visible;
}
.FormFieldSelect_variantOutlined .FormFieldSelect_inputRow::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: inherit;
  background-color: transparent;
  user-select: none;
  pointer-events: none;
  border: 1px solid var(--color_text_secondary);
}
.FormFieldSelect_variantOutlined:focus-within .FormFieldSelect_inputRow {
  border-color: var(--color_primary_main);
}
.FormFieldSelect_variantOutlined:focus-within .FormFieldSelect_inputRow::before {
  border-color: transparent;
}

.FormFieldSelect_variantNoBackground .FormFieldSelect_label {
  color: var(--color_text_primary);
}
.FormFieldSelect_variantNoBackground .FormFieldSelect_inputRow {
  padding-left: 1.5rem;
  border-radius: 0.5rem;
  background-color: transparent;
}

.FormFieldSelect_variantContained .FormFieldSelect_content {
  background-color: rgba(255, 255, 255, 0.09);
  padding: 1.5rem;
}
.FormFieldSelect_variantContained .FormFieldSelect_label {
  color: var(--color_text_secondary);
}
.FormFieldSelect_variantContained:focus-within .FormFieldSelect_label, .FormFieldSelect_variantContained.FormFieldSelect_filled .FormFieldSelect_label {
  transform: translate(0rem, -0.25rem) scale(0.8);
}
.FormFieldSelect_variantContained .FormFieldSelect_inputRow {
  padding-left: 1.5rem;
  border-radius: 0.5rem;
  background-color: transparent;
}

.FormFieldSelect_fullWidth .FormFieldSelect_content, .FormFieldSelect_fullWidth .FormFieldSelect_contentStack, .FormFieldSelect_fullWidth .FormFieldSelect_inputRow {
  width: 100%;
}

.FormFieldSelect_sizeSm .FormFieldSelect_inputRow {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.FormFieldSelect_sizeSm .FormFieldSelect_label {
  /* Body/Regular */
  font-style: normal;
  /* BodySM */
  font-size: 1.6rem; /* 16px */
  line-height: 140%; /* 22.4px */
  /* Body/Regular */
  font-weight: 400;
}
.FormFieldSelect_sizeSm .FormFieldSelect_input {
  /* Body/Regular */
  font-style: normal;
  /* BodySM */
  font-size: 1.6rem; /* 16px */
  line-height: 140%; /* 22.4px */
  /* Body/Regular */
  font-weight: 400;
  min-height: 2.2rem;
}
.FormFieldSelect_sizeSm .FormFieldSelect_arrowIcon {
  font-size: 3rem;
}

.FormFieldSelect_sizeMd .FormFieldSelect_inputRow {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.FormFieldSelect_sizeMd .FormFieldSelect_label {
  /* Body/Regular */
  font-style: normal;
  /* Body/Regular */
  font-size: 2rem; /* 20px */
  line-height: 140%; /* 28px */
  /* Body/Regular */
  font-weight: 400;
}
.FormFieldSelect_sizeMd .FormFieldSelect_input {
  /* Body/Regular */
  font-style: normal;
  /* Body/Regular */
  font-size: 2rem; /* 20px */
  line-height: 140%; /* 28px */
  /* Body/Regular */
  font-weight: 400;
  min-height: 3rem;
}
.FormFieldSelect_sizeMd .FormFieldSelect_arrowIcon {
  font-size: 3.6rem;
}

.FormFieldSelect_sizeXl .FormFieldSelect_inputRow {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.FormFieldSelect_sizeXl .FormFieldSelect_label {
  /* Body/Regular */
  font-style: normal;
  /* Body/Regular */
  font-size: 2rem; /* 20px */
  line-height: 140%; /* 28px */
  /* Body/SemiBold */
  font-weight: 700;
}
.FormFieldSelect_sizeXl .FormFieldSelect_input {
  /* H6/Bold */
  font-family: "Century-Gothic-Pro";
  font-size: 2.8rem; /* 28px */
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 28px */
  min-height: 3rem;
}
.FormFieldSelect_sizeXl .FormFieldSelect_arrowIcon {
  font-size: 4rem;
}

.FormFieldSelect_min_width_sm .FormFieldSelect_content {
  min-width: 10rem;
}
.FormFieldSelect_min_width_sm .FormFieldSelect_contentStack, .FormFieldSelect_min_width_sm .FormFieldSelect_inputRow {
  width: 100%;
}

.FormFieldSelect_min_width_md .FormFieldSelect_content {
  min-width: 20rem;
}
.FormFieldSelect_min_width_md .FormFieldSelect_contentStack, .FormFieldSelect_min_width_md .FormFieldSelect_inputRow {
  width: 100%;
}

.FormFieldSelect_min_width_lg .FormFieldSelect_content {
  min-width: 30rem;
}
.FormFieldSelect_min_width_lg .FormFieldSelect_contentStack, .FormFieldSelect_min_width_lg .FormFieldSelect_inputRow {
  width: 100%;
}