.CashBankView_root {
  height: 100%;
}

.CashBankView_fullHeight {
  height: 100%;
}

padding {
  padding: 1rem;
}