.CurrentCheckItemButtons_root {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

.CurrentCheckItemButtons_scrollContainer {
  overflow-y: auto;
  scroll-behavior: smooth;
  flex: 1;
}

.CurrentCheckItemButtons_content {
  padding-bottom: 2rem;
}