.CheckDetailPrintJobsTab_root {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.CheckDetailPrintJobsTab_grid {
  display: grid;
  grid-template-columns: [col1] 0 [col2] 2fr [col3] 4fr [col4] 3fr [col5] 2fr;
  gap: 1rem;
  padding: 1rem 0;
}

.CheckDetailPrintJobsTab_gridHeader {
  padding: 1.8rem 0;
  border-bottom: 1px solid var(--color_grey_500);
}

.CheckDetailPrintJobsTab_col1Header > * {
  display: none;
}

.CheckDetailPrintJobsTab_col1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.CheckDetailPrintJobsTab_col1 > * {
  display: none;
}

.CheckDetailPrintJobsTab_col3 {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.CheckDetailPrintJobsTab_col5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.CheckDetailPrintJobsTab_scrollGrid {
  overflow: auto;
}

.CheckDetailPrintJobsTab_isNoPrint {
  opacity: 0.5;
}