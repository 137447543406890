.PrinterStatusNotification_root {
  padding: 3rem;
}

.PrinterStatusNotification_message {
  wordBreak: break-word;
  flexGrow: 1;
}

.PrinterStatusNotification_time {
  width: 6rem;
  color: var(--color_text_secondary);
}

.PrinterStatusNotification_icon {
  font-size: 2rem;
  width: 3rem;
  lineHeight: 3rem;
  text-align: center;
  borderRadius: 5rem;
}