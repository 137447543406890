@import '/home/runner/work/pos/pos/src/styles/sass/_globals.scss';
/*css reset*/
html, body, div, button, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  /* font reset */
  font-family: "Sofia-Pro", sans-serif;
  font-style: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div, span, h1, h2, h3, h4, h5, h6, p,
blockquote, pre, a, abbr, acronym, address, big, cite, code,
small, strike, strong, sub, sup, b, u, i, center,
dt, dd, ol, ul, li, fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, details, figure, figcaption, footer, header,
hgroup, menu, nav, section, summary, time, mark, audio, video {
  //@include font-variant('body');
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q::before, q::after {
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

/*base rules for fonts*/
html {
  /*62.5% of 16px = 10px*/
  font-size: 10px;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  /* BLOCK REFRESH UI FROM ANDROID HARDWARE */
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden; // BLOCK REFRESH UI FROM ANDROID HARDWARE
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  color: color-var(color_text_primary);
  background-color: color-var(color_bg_main);
  &::-webkit-scrollbar, & *::-webkit-scrollbar {
    display: none;
  }
}

/* transition delay hack to stop auto fill from changing styles */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: all 0s 50000s;
}
