.HeaderTimeStamp_root {
  overflow: hidden;
  user-select: none;
  display: flex;
  align-items: center;
  touch-action: pan-x;
}

.HeaderTimeStamp_reveal {
  overflow: hidden;
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
}

.HeaderTimeStamp_reveal-content {
  display: inline-flex;
  flex-direction: row;
}

.HeaderTimeStamp_clock-container {
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 2rem;
}