.FloorPlanActions_form-switch {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FloorPlanActions_form-section {
  border-bottom: 1px solid var(--color_divider);
}

.FloorPlanActions_radio-group {
  margin-left: 2rem;
}