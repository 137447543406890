.FormFieldNumberInput_root {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.FormFieldNumberInput_root.FormFieldNumberInput_expandable .FormFieldNumberInput_keys {
  padding-top: 2rem;
}
.FormFieldNumberInput_root.FormFieldNumberInput_expandable .FormFieldNumberInput_label {
  /* Body/Regular */
  font-style: normal;
  /* Body/Regular */
  font-size: 2rem; /* 20px */
  line-height: 140%; /* 28px */
  /* Body/Regular */
  font-weight: 400;
  color: var(--color_text_primary);
  user-select: none;
  pointer-events: none;
}
.FormFieldNumberInput_root.FormFieldNumberInput_expandable .FormFieldNumberInput_input {
  /* Body/Regular */
  font-style: normal;
  /* Body/Regular */
  font-size: 2rem; /* 20px */
  line-height: 140%; /* 28px */
  /* Body/SemiBold */
  font-weight: 700;
  text-align: right;
}
.FormFieldNumberInput_root.FormFieldNumberInput_expandable .FormFieldNumberInput_text-field {
  width: 100%;
}

.FormFieldNumberInput_label {
  /* Body/Regular */
  font-style: normal;
  /* BodySM */
  font-size: 1.6rem; /* 16px */
  line-height: 140%; /* 22.4px */
  /* Body/SemiBold */
  font-weight: 700;
}

.FormFieldNumberInput_input {
  /* Body/Regular */
  font-style: normal;
  /* Body/Regular */
  font-size: 2rem; /* 20px */
  line-height: 140%; /* 28px */
  /* Body/Regular */
  font-weight: 400;
  justify-self: center;
}

.FormFieldNumberInput_text-field {
  border-bottom: 1px solid var(--color_divider);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3rem 1rem 1rem 1rem;
  align-items: center;
}