.InvertedCheckbox_container {
  background-color: var(--color_grey_600);
  margin: 1rem;
  border-radius: 0.3rem;
}

.InvertedCheckbox_invisible {
  visibility: hidden;
}

.InvertedCheckbox_icon {
  display: block;
}