.StatusAdornment_root {
  display: flex;
  align-items: center;
  justify-content: center;
}
@keyframes StatusAdornment_pulse {
  0% {
    opacity: 0.1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}
.StatusAdornment_root.StatusAdornment_pulse .StatusAdornment_adornment {
  animation: StatusAdornment_pulse 2s infinite;
}
.StatusAdornment_root.StatusAdornment_size_xs .StatusAdornment_adornment {
  width: 0.3rem;
  height: 0.3rem;
}
.StatusAdornment_root.StatusAdornment_size_sm .StatusAdornment_adornment {
  width: 0.6rem;
  height: 0.6rem;
}
.StatusAdornment_root.StatusAdornment_size_md .StatusAdornment_adornment {
  width: 1rem;
  height: 1rem;
}
.StatusAdornment_root.StatusAdornment_size_lg .StatusAdornment_adornment {
  width: 1.2rem;
  height: 1.2rem;
}
.StatusAdornment_root.StatusAdornment_size_xl .StatusAdornment_adornment {
  width: 2rem;
  height: 2rem;
}

.StatusAdornment_adornment {
  opacity: 1;
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
}
.StatusAdornment_adornment.StatusAdornment_color_primary {
  background-color: var(--color_primary_main);
}
.StatusAdornment_adornment.StatusAdornment_color_secondary {
  background-color: var(--color_secondary_main);
}
.StatusAdornment_adornment.StatusAdornment_color_success {
  background-color: var(--color_success_main);
}
.StatusAdornment_adornment.StatusAdornment_color_info {
  background-color: var(--color_info_main);
}
.StatusAdornment_adornment.StatusAdornment_color_warning {
  background-color: var(--color_warn_main);
}
.StatusAdornment_adornment.StatusAdornment_color_error {
  background-color: var(--color_error_main);
}
.StatusAdornment_adornment.StatusAdornment_color_error_lite {
  background-color: var(--color_error_lite);
}
.StatusAdornment_adornment.StatusAdornment_color_unknown {
  background-color: var(--color_text_primary);
}