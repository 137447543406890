.StackedIconCTA_root {
  background-color: transparent;
  display: flex;
  flex-direction: column;
}
.StackedIconCTA_root:hover {
  background-color: transparent;
}
.StackedIconCTA_root.IconCTA_root .IconCTA_icon::before {
  display: none;
}

.StackedIconCTA_icon_container {
  border-radius: 50%;
  padding: 2rem;
  background-color: var(--color_transparent_dark_50);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.StackedIconCTA_icon_container > * {
  border-radius: inherit;
  position: relative;
}
@keyframes StackedIconCTA_pulse {
  0% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.1;
  }
}
.StackedIconCTA_icon_container > *::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  left: 0;
  top: 0;
  border-radius: inherit;
  background-color: white;
  user-select: none;
  pointer-events: none;
}
.StackedIconCTA_icon_container > *:active::before {
  animation: StackedIconCTA_pulse 0.75s;
}
.StackedIconCTA_icon_container svg {
  width: 4rem;
  height: 4rem;
}

.StackedIconCTA_icon_sm {
  transform: scale(0.7);
}
.StackedIconCTA_label_container {
  font-size: 1.3rem;
  padding: 1rem;
}

.StackedIconCTA_label_sm {
  padding: 0.5rem;
  font-style: normal;
  /* BodySM */
  font-size: 1.6rem; /* 16px */
  line-height: 1.6rem; /* 16px */
  /* Body/Regular */
  font-weight: 400;
}

.StackedIconCTA_label_lg {
  font-style: normal;
  /* BodyLG */
  font-size: 2.4rem; /* 24px */
  line-height: 2.4rem; /* 24px */
  /* Body/Regular */
  font-weight: 400;
}