.StyleGuideUiBlocker_root {
  position: relative;
}

.StyleGuideUiBlocker_uiBlocker {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
.StyleGuideUiBlocker_uiBlocker:hover .StyleGuideUiBlocker_message {
  opacity: 1;
}

.StyleGuideUiBlocker_message {
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background: rgba(255, 255, 255, 0.3);
  text-shadow: 2px 2px black;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  opacity: 0;
}