.MenuItemButtons_horizontal-scroll-container {
  height: 100%;
  display: flex;
  grid-gap: 2em;
  overflow: auto;
}

.MenuItemButtons_menu-group-buttons-container {
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  grid-gap: 1rem;
}

.MenuItemButtons_menu-group-header {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  max-width: 100%;
  overflow: hidden;
}

.MenuItemButtons_menu-group-buttons {
  display: grid;
  grid-gap: 1em;
  grid-auto-flow: column;
  grid-auto-columns: 19rem;
}
@media (min-height: 20rem) {
  .MenuItemButtons_menu-group-buttons {
    grid-template-rows: repeat(2, 1fr);
  }
}
@media (min-height: 33rem) {
  .MenuItemButtons_menu-group-buttons {
    grid-template-rows: repeat(3, 1fr);
  }
}
@media (min-height: 38rem) {
  .MenuItemButtons_menu-group-buttons {
    grid-template-rows: repeat(4, 1fr);
  }
}
@media (min-height: 49rem) {
  .MenuItemButtons_menu-group-buttons {
    grid-template-rows: repeat(6, 1fr);
  }
}
@media (min-height: 56rem) {
  .MenuItemButtons_menu-group-buttons {
    grid-template-rows: repeat(7, 1fr);
  }
}