.CheckActivityRow_root {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 1rem;
  background-color: transparent;
  border-bottom: 1px solid var(--color_divider);
}

.CheckActivityRow_inner-grid {
  width: 100%;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: auto auto;
}

.CheckActivityRow_grid-item-fill {
  grid-column-start: 1;
  grid-column-end: -1;
}

.CheckActivityRow_outer-grid {
  width: 100%;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 15rem 20rem auto;
}

.CheckActivityRow_grid {
  width: 100%;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: auto;
}

.CheckActivityRow_modifier-name {
  margin-left: 1rem;
}