.CheckItemButtonDiscounts_root {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.CheckItemButtonDiscounts_chip {
  border-radius: 4rem;
  padding: 0.25rem 0.5rem;
  border: 1px solid var(--color_warn_main);
}