.TitleItems_root {
  padding: 1rem;
}

.TitleItems_rounded {
  border-radius: 1rem;
}

.TitleItems_large {
  padding: 2rem;
}

.TitleItems_inner_flex > * {
  flex-basis: 0;
}