.Page_root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.Page_root.Page_has_training_mode > .Page_content {
  height: calc(100% - 3.5rem);
}
.Page_root.Page_has_panel > .Page_content {
  grid-template-columns: minmax(30rem, 30%) 1fr;
}

.Page_content {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  width: 100%;
  height: 100%;
}

.Page_training_mode {
  width: 100%;
  height: 3.5rem;
  background-color: var(--color_success_main);
  padding: 1rem 0;
  text-align: center;
}

.Page_panel {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: var(--color_bg_secondary);
  overflow: hidden;
}

.Page_main {
  position: relative;
  overflow: hidden;
}