.CheckDiscountModal_wrapper {
  margin: 4.8rem;
}

.CheckDiscountModal_grid {
  display: grid;
  row-gap: 1rem;
  grid-template-columns: 6fr 1fr 3fr;
  margin-bottom: 4.8rem;
}
.CheckDiscountModal_grid > * {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color_bg_main);
  height: 6rem;
  padding: 1rem;
}

.CheckDiscountModal_discountAmout {
  display: flex;
  flex-direction: row;
  justify-content: end;
  justify-self: flex-end;
  width: 100%;
}

.CheckDiscountModal_actions {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  margin: 0 4.8rem 4.8rem 4.8rem;
  gap: 2.4rem;
}