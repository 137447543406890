.FormFieldInput_root {
  -webkit-tap-highlight-color: transparent;
  background: none;
  border: 0;
  box-sizing: content-box;
  color: var(--color_text_primary);
  display: block;
  font-family: inherit;
  padding: 0;
  margin: 0;
  min-width: 0;
  user-select: none;
}
.FormFieldInput_root:focus-visible {
  outline: none;
}
.FormFieldInput_root.FormFieldInput_size_sm {
  /* Body/Regular */
  font-style: normal;
  /* BodySM */
  font-size: 1.6rem; /* 16px */
  line-height: 140%; /* 22.4px */
  /* Body/Regular */
  font-weight: 400;
}
.FormFieldInput_root.FormFieldInput_size_md {
  /* Body/Regular */
  font-style: normal;
  /* Body/Regular */
  font-size: 2rem; /* 20px */
  line-height: 140%; /* 28px */
  /* Body/Regular */
  font-weight: 400;
}
.FormFieldInput_root.FormFieldInput_disabled {
  opacity: 0.5;
}
.FormFieldInput_root.FormFieldInput_variant_underline_box {
  padding: 1rem;
  flex-grow: 1;
  color: var(--color_text_primary);
  border-bottom: 1px solid var(--color_divider);
  background-color: var(--color_bg_secondary);
}
.FormFieldInput_root.FormFieldInput_variant_underline_box.FormFieldInput_error {
  border-bottom: 1px solid var(--color_error_main);
}
.FormFieldInput_root.FormFieldInput_variant_underline {
  padding: 0;
  flex-grow: 1;
  color: var(--color_text_primary);
  border-bottom: 1px solid var(--color_divider);
  background-color: transparent;
}
.FormFieldInput_root.FormFieldInput_variant_underline.FormFieldInput_error {
  border-bottom: 1px solid var(--color_error_main);
}
.FormFieldInput_root.FormFieldInput_variant_no_background {
  padding: 1rem;
  flex-grow: 1;
  color: var(--color_text_primary);
  background-color: transparent;
}
.FormFieldInput_root.FormFieldInput_variant_no_background_centered {
  padding: 1rem;
  flex-grow: 1;
  color: var(--color_text_primary);
  background-color: transparent;
  text-align: center;
}