.PageMainContentOverlay_root {
  position: absolute;
  user-select: none;
  pointer-events: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  left: 0;
  top: 0;
  z-index: 3;
}
.PageMainContentOverlay_root.PageMainContentOverlay_open .PageMainContentOverlay_shadow {
  opacity: 0.5;
}
.PageMainContentOverlay_root.PageMainContentOverlay_open .PageMainContentOverlay_overlay {
  left: 0;
}
.PageMainContentOverlay_root .PageMainHeader_root {
  background-color: transparent;
}

.PageMainContentOverlay_overlay {
  position: absolute;
  pointer-events: all;
  width: calc(100% - 3rem);
  height: 100%;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  background-color: var(--color_bg_tertiary);
  left: -100%;
  top: 0;
  padding: 0 1rem;
  transition: left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.PageMainContentOverlay_shadow {
  position: absolute;
  width: 2rem;
  height: 100%;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  opacity: 0;
  left: 0;
  top: 0;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0));
}