// t3 has multiple display sizes that can be selected
// - each notch from left to right
// 1. 1507x849  - Notch 0 - All the way left (aka most screen real estate)
// 2. 1280x720  - Notch 1
// 3. 1098x619  - Notch 2
// 4. 960x540   - Notch 3
// 5. 854x480   - Notch 4 - All the way right (aka least screen real estate)
// using pixel perfect sizes makes me unsure
//$-t3-display-width-map: (xs: 854px, sm: 960px, md: 1098px, lg: 1280px, xl: 1507px);
// revising sizes to use size up
$-t3-display-width-map: (
  xs: 850px,
  sm: 950px,
  md: 1050px,
  lg: 1250px,
  xl: 1500px
);
//$-t3-display-height-map: (xs: 480px, sm: 540px, md: 619px, lg: 720px, xl: 849px);

$-breakpoints-map: (
  xs: 0px,
  sm: 600px,
  md: 900px,
  lg: 1200px,
  xl: 1536px,
);

@function get-t3-width($size) {
  @if(map-has-key($-t3-display-width-map, $size)){
    @return map-get($-t3-display-width-map, $size);
  } @else {
    @error "get-t3-width: unknown map key of: `#{$size}` for $-t3-display-width-map: `#{$-t3-display-width-map}`";
    @return null;
  }
}

@function get-breakpoint($size) {
  @if map-has-key($map: $-breakpoints-map, $key: $size) {
    @return map-get($map: $-breakpoints-map, $key: $size);
  } @else {
    @error "get-breakpoint: unknown map key of: `#{$size}` for $-breakpoints-map: `#{$-breakpoints-map}`";
    @return null;
  }
}

@mixin breakpoints-down($size) {
  @media (max-width: get-breakpoint($size)) {
    @content;
  }
}

@mixin breakpoints-up($size) {
  @media (min-width: get-breakpoint($size)) {
    @content;
  }
}

@mixin breakpoints-t3-up($size) {
  @media (min-width: get-t3-width($size)) {
    @content;
  }
}
