.AppLoader_root {
  position: relative;
  height: 100%;
}

.AppLoader_loader_anim_wrapper {
  position: absolute;
  z-index: 1000;
}

.AppLoader_clear_backdrop {
  background-color: unset;
}

.AppLoader_loader {
  width: 60px;
  height: 60px;
}