.FormFieldPin_content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.FormFieldPin_input {
  color: transparent !important;
}

.FormFieldPin_pin-overlay {
  position: absolute;
  width: 100%;
  height: 3rem;
  top: 1.5rem;
}