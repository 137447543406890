.MenuEditModal_page {
  padding: 0 3rem;
}

.MenuEditModal_menu {
  display: grid;
  grid-template-rows: 1fr;
  height: 100%;
  gap: 1rem;
}
.MenuEditModal_menu.MenuEditModal_show-menu-group-buttons {
  grid-template-rows: auto 1fr;
  grid-template-columns: minmax(0, 1fr);
}