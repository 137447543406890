.CheckPagePayment_root {
  height: 100%;
  padding: 1rem;
}

.CheckPagePayment_grid-col4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
  padding-bottom: 5rem;
}

.CheckPagePayment_grid-col5 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 1rem;
  padding-bottom: 5rem;
}

.CheckPagePayment_grid-col2 {
  display: grid;
  grid-template-columns: 20% 20%;
  justify-content: center;
  gap: 1rem;
}

.CheckPagePayment_activity-root {
  height: 50%;
  padding-bottom: 5rem;
}

.CheckPagePayment_activity {
  height: 70rem;
  overflow-y: scroll;
}